<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <template v-if="this.$route.name == 'add-touchpoint'">
        <h4 class="mb-0">Add Touchpoint</h4>
        <div class="page-title-right">
          <router-link :to="{
            name: 'view-single-campaign',
            params: { id: $route.params.id }
          }">
            <span style="color: grey">Back</span>
          </router-link>
        </div>
      </template>
      <template v-else>
        <h4 class="mb-0">Edit Touchpoint</h4>
        <div class="page-title-right">
          <router-link :to="{
            name: 'view-single-campaign',
            params: { id: $route.query.campaign_id }
          }">
            <span style="color: grey">Back</span>
          </router-link>
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="card">
          <div class="card-body">
            <b-form>
              <b-form-group label="Pick your Channel" label-for="radio-group-1">
                <b-form-radio-group id="radio-group-1" v-model="form.notification_type" :options="typeOpt"
                  name="radio-options" @input="changeNotificationType"></b-form-radio-group>
              </b-form-group>
              <b-form-group>
                <label for="input-2">Touchpoint Name <span style="color: red">*</span></label>
                <b-form-input id="input-2" v-model="form.engagement_name" placeholder="Enter Touchpoint Name" :class="{
                  'is-invalid': submitted && $v.form.engagement_name.$error,
                }"></b-form-input>
                <div v-if="submitted && !$v.form.engagement_name.required" class="invalid-feedback">
                  Touchpoint Name is required.
                </div>
              </b-form-group>
              <b-form-group label="Scheduled Date" label-for="input-6">
                <b-form-input id="input-6" v-model="form.scheduled_time" type="datetime-local" :min="disabledDates()">
                </b-form-input>
              </b-form-group>
              <template v-if="form.notification_type == 'whatsapp'">
                <b-form-group>
                  <div class="d-flex justify-content-between align-items-center">
                    <label for="wn">Pick Whatsapp Numbers</label>
                  </div>
                  <b-form-select
                    id="wn"
                    v-model="form.from_number"
                    :options="whatsappConfigOptions"
                    value-field="endpoint_name"
                    text-field="displayText"
                  ></b-form-select>
                </b-form-group>
                <b-form-group>
                  <div class="d-flex justify-content-between align-items-center">
                    <label for="wt">Pick Whatsapp Template <span style="color: red">*</span></label>
                    <b-button class="p-2 mb-2 btn-sm" v-b-tooltip.hover.html="tipData" variant="info">Info</b-button>
                  </div>
                  <b-form-select id="wt" v-model="form.template_id" @change="getVariables" :options="getWhatsappTemplate"
                    value-field="id" text-field="template_name" :class="{
                      'is-invalid': submitted && $v.form.template_id.$error,
                    }"></b-form-select>
                  <div v-if="submitted && !$v.form.template_id.required" class="invalid-feedback">
                    Pick your Whatsapp is required.
                  </div>
                </b-form-group>
                <div class="row" v-for="(val, index) in form.variables" :key="index">
                  <b-form-group class="col-12">
                    <b-form-input v-model="val.value" @input="changeVar"></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group>
                  <label for="input-8">Pick Your Content <span style="color: red">*</span></label>
                  <b-form-select id="input-8" :key="action_key" value-field="value" text-field="text"
                    v-model="form.action_type" :options="actionType" @input="changeType"
                    :class="{ 'is-invalid': submitted && $v.form.action_type.$error }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.action_type.required" class="invalid-feedback">Pick your Content is
                    required.</div>
                </b-form-group>
                <template v-if="form.action_type != null && form.action_type != 'custom'">
                  <b-form-group id="input-group-9" :label="form.action_type == 'video' ? 'Action ID : Video'
                    : form.action_type == 'newsletter' ? 'Action ID : Newsletter'
                      : form.action_type == 'case' ? 'Action ID : Case'
                        : form.action_type == 'newsarticle' ? 'Action ID : Article'
                          : form.action_type == 'live_event' ? 'Action ID : Live Event'
                          : form.action_type == 'post' ? 'Action ID : Post'
                            : ''" label-for="input-9">
                    <template
                      v-if="form.action_type == 'case' || form.action_type == 'newsletter' || form.action_type == 'video' || form.action_type == 'post'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="title" @search-change="fetchAttachment"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                    <template v-else-if="form.action_type == 'newsarticle'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="header" @search-change="fetchAttachmentBySearch"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                    <template v-else-if="form.action_type == 'live_event'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="title" @search-change="fetchLiveEventBySearch"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                  </b-form-group>
                </template>
                <template v-if="form.action_type == 'custom' && this.selectedVariable.has_media">
                  <b-form-group id="example-img" label="Upload Image" label-for="img" class="mt-1">
                    <b-form-file id="img" accept="image/png, image/jpeg, image/jpg"
                      placeholder="Choose a file or drop it here..." @change="readFile($event, 'image')" ref="image">
                    </b-form-file>
                    <template v-if="$route.name == 'edit-touchpoint' && edit.image_url">
                      <img :src="edit.image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="image_url">
                      <img :src="image_url" width="128px" height="128px" ref="image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </template>
                <b-form-group id="input-4">
                  <label for="input-grp-4">Pick Your Audience <span style="color: red">*</span></label>
                  <b-form-select id="input-grp-4" @change="changeSendType" v-model="form.send_to_medium"
                    value-field="value" text-field="text" :options="sendToMedium"
                    :class="{ 'is-invalid': submitted && $v.form.send_to_medium.$error }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.send_to_medium.required" class="invalid-feedback">Pick Your Audience
                    is required.</div>
                </b-form-group>
                <template v-if="form.send_to_medium == 'data_filter'">
                  <h6>From Data Filters</h6>
                  <b-form-group id="example-tel" label="Data Filter" label-for="i10">
                    <b-form-select id="i10" v-model="form.data_filter_id" value-field="id" text-field="name"
                      :options="dataFilter" :class="{ 'is-invalid': submitted && $v.form.data_filter_id.$error }">
                    </b-form-select>
                    <div v-if="submitted && !$v.form.data_filter_id.required" class="invalid-feedback">Data Filter is
                      required.</div>
                  </b-form-group>
                </template>
                <template v-if="form.send_to_medium == 'csv'">
                  <h6>From File</h6>
                  <b-form-group class="mt-2">
                    <div class="d-flex justify-content-between">
                      <label for="input-11">Upload CSV File <span style="color: red;">*</span></label>
                      <a href="/test_data_whatsapp.csv" download>
                        <i class="fa fa-download"></i> Download Sample CSV
                      </a>
                    </div>
                    <b-form-file accept=".csv" id="input-11" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'csv_file')"></b-form-file>
                    <template v-if="$route.name == 'edit-touchpoint'">
                      <div class="mt-2">CSV File: {{ edit.csv_file_url }}</div>
                    </template>
                  </b-form-group>
                </template>
                <template v-if="form.send_to_medium == 'test'">
                  <b-card header-class="bg-transparent border-primary" class="border border-primary">
                    <div>
                      <h5 class="my-0 text-primary"><i class="mdi mdi-bullseye-arrow mr-3"></i>For Test</h5>
                      <b-form-group id="example-tel" label-cols-lg="4" label="Enter Mobile Number" label-for="i12">
                        <b-form-input v-model="form.mobile_numbers" class="col-8" id="i12"
                          :class="{ 'is-invalid': submitted && $v.form.mobile_numbers.$error }">
                          >
                        </b-form-input>
                        <div v-if="submitted && !$v.form.mobile_numbers.required" class="invalid-feedback">Mobile Number
                          is required</div>
                      </b-form-group>
                    </div>
                  </b-card>
                </template>
              </template>
              <template v-else-if="form.notification_type == 'app_notification'">
                <b-form-group>
                  <label for="input-6">Notification Title <span style="color: red;">*</span></label>
                  <b-form-input id="input-6" v-model="form.notification_title"
                    :class="{ 'is-invalid': submitted && $v.form.notification_title.$error }">
                  </b-form-input>
                  <div v-if="submitted && !$v.form.notification_title.required" class="invalid-feedback">Notification
                    Title is required.</div>
                </b-form-group>
                <b-form-group>
                  <label for="input-7">Notification Description <span style="color: red;">*</span></label>
                  <b-form-input id="input-7" v-model="form.notification_description"
                    :class="{ 'is-invalid': submitted && $v.form.notification_description.$error }">
                  </b-form-input>
                  <div v-if="submitted && !$v.form.notification_description.required" class="invalid-feedback">
                    Notification Description is required.</div>
                </b-form-group>
                <b-form-group id="example-tel">
                  <label for="tele">Device Type <span style="color: red;">*</span></label>
                  <b-form-select id="tele" v-model="form.device_type" value-field="value" text-field="text"
                    :options="deviceType" :class="{ 'is-invalid': submitted && $v.form.device_type.$error }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.device_type.required" class="invalid-feedback">Device Type is
                    required.</div>
                </b-form-group>
                <b-form-group>
                  <label for="input-8">Pick Your Content <span style="color: red">*</span></label>
                  <b-form-select id="input-8" :key="action_key" value-field="value" text-field="text"
                    v-model="form.action_type" :options="actionType" @input="changeType"
                    :class="{ 'is-invalid': submitted && $v.form.action_type.$error }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.action_type.required" class="invalid-feedback">Pick your Content is
                    required.</div>
                </b-form-group>
                <template v-if="form.action_type != null && form.action_type != 'custom'">
                  <b-form-group id="input-group-9" :label="form.action_type == 'video' ? 'Action ID : Video'
                    : form.action_type == 'newsletter' ? 'Action ID : Newsletter'
                      : form.action_type == 'case' ? 'Action ID : Case'
                        : form.action_type == 'newsarticle' ? 'Action ID : Article'
                          : form.action_type == 'live_event' ? 'Action ID : Live Event'
                          : form.action_type == 'post' ? 'Action ID : Post'
                            : ''" label-for="input-9">
                    <template
                      v-if="form.action_type == 'case' || form.action_type == 'newsletter' || form.action_type == 'video' || form.action_type == 'post'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="title" @search-change="fetchAttachment"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                    <template v-else-if="form.action_type == 'newsarticle'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="header" @search-change="fetchAttachmentBySearch"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                    <template v-else-if="form.action_type == 'live_event'">
                      <multiselect v-model="form.action_id" :options="action_type" :multiple="false" track-by="id"
                        label="title" @search-change="fetchLiveEventBySearch"
                        :class="{ 'is-invalid': submitted && $v.form.action_id.$error }"
                        placeholder="Type here to search">
                        <span slot="noOptions">Type here to search</span>
                      </multiselect>
                      <div v-if="submitted && !$v.form.action_id.required" class="invalid-feedback">Action ID is
                        required.</div>
                    </template>
                  </b-form-group>
                </template>
                <b-form-group id="input-4">
                  <label for="input-grp-4">Pick Your Audience <span style="color: red">*</span></label>
                  <b-form-select id="input-grp-4" @change="changeSendType" v-model="form.send_to_medium"
                    value-field="value" text-field="text" :options="sendToMedium"
                    :class="{ 'is-invalid': submitted && $v.form.send_to_medium.$error }">
                  </b-form-select>
                  <div v-if="submitted && !$v.form.send_to_medium.required" class="invalid-feedback">Pick Your Audience
                    is required.</div>
                </b-form-group>
                <template v-if="form.send_to_medium == 'test'">
                  <b-card header-class="bg-transparent border-primary" class="border border-primary">
                    <div>
                      <h5 class="my-0 text-primary">
                        <i class="mdi mdi-bullseye-arrow mr-3"></i>For Test
                      </h5>
                      <b-form-group id="example-tel" label-cols-lg="4" label="Enter Mobile Number" label-for="i12">
                        <b-form-input v-model="form.mobile_numbers" class="col-8" id="i12"
                          :class="{ 'is-invalid': submitted && $v.form.mobile_numbers.$error }"></b-form-input>
                        <div v-if="submitted && !$v.form.mobile_numbers.required" class="invalid-feedback">Mobile Number
                          is required</div>
                      </b-form-group>
                    </div>
                  </b-card>
                </template>
                <template v-if="form.send_to_medium == 'speciality'">
                  <b-form-group>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <label for="spec" class="mb-0">Speciality
                        <span style="color: red"> *</span></label>
                      <b-form-checkbox v-model="specialityStatus" button button-variant="info" size="sm">
                        <template v-if="specialityStatus">Unselect All</template>
                        <template v-else>Select All</template>
                      </b-form-checkbox>
                    </div>
                    <multiselect id="spec" v-model="form.speciality_selected" :options="specialities" :multiple="true"
                      track-by="id" label="title" @search-change="fetchSpeciality" placeholder="Type here to search"
                      :class="{ 'is-invalid': submitted && $v.form.speciality_selected.$error }">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                    <div v-if="submitted && !$v.form.speciality_selected.required" class="invalid-feedback">Speciality
                      is required when Pick your Audience is Speciality</div>
                  </b-form-group>
                </template>
              </template>
              <template v-if="$route.name == 'edit-touchpoint'">
                <b-button type="button" variant="primary" class="mr-2" @click.prevent="submitData">
                  Update Message</b-button>
              </template>
              <template v-else-if="$route.name == 'add-touchpoint'">
                <b-button type="button" variant="success" class="mr-2" @click.prevent="submitData"><span
                    v-if="form.send_to_medium == 'test'">Send Message</span>
                  <span v-else>Store Message</span>
                </b-button>
                <b-button type="button" variant="primary" class="mr-2" @click.prevent="submitData('check')">Check Message
                </b-button>
              </template>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <div class="position-absolute">
          <template v-if="this.form.notification_type == 'whatsapp'">
            <img src="/asset.jpg" alt="" class="position-relative" />
          </template>
          <template v-else>
            <img src="/asset.jpg" alt="" class="position-relative" />
          </template>
          <div class="card app-card">
            <template v-if="form.notification_type == 'whatsapp'">
              <div class="card-img mb-2">
                <template v-if="form.image != null && form.image != ''">
                  <img :src="`${form.image}`" alt="" height="80px" width="147px" style="border-radius: 10px" />
                </template>
                <template v-else>
                  <img src="../../../../public/placeholder/placeholder-article.png" alt="" height="80px" width="147px"
                    style="border-radius: 10px" />
                </template>
              </div>
              <div class="card-body px-0 py-0">
                <h6 class="mb-0">Dear Dr. [Name]</h6>
                <div class="app-notification">
                  <template v-if="checkContent">
                    <div v-for="item in form.contentParams" :key="item.id">
                      <div class="mb-2">{{ item }}</div>
                    </div>
                  </template>
                  <template v-else>
                    <div v-for="items in form.variables" :key="items.id">
                      <div class="mb-2">{{ items.value }}</div>
                    </div>
                  </template>
                </div>
                <h6 class="mb-1">Regards,</h6>
                <h6 class="mb-0">Team Medisage</h6>
              </div>
            </template>
            <template v-else-if="form.notification_type == 'app_notification'">
              <h5 class="title">{{ form.notification_title }}</h5>
              <h6 class="desc">{{ form.notification_description }}</h6>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import touchpointMixin from "../../../mixins/ModuleJs/touchpoint";
import { requiredIf, required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      tipData: { title: '1. If you want to add Title use {{ title }} in variable. <br> 2. If you want to add UTM Link use #powerlink in variable. <br> 3. If you want to add App Link use {{ app_link }} in variable.' },
      submitted: false,
      title1: "Add Touchpoint",
      title2: "Edit Touchpoint",
    };
  },
  components: {
    Layout,
    Multiselect
  },
  mixins: [MixinRequest, touchpointMixin],
  validations: {
    form: {
      engagement_name: { required: requiredIf(form => form.notification_type == 'whatsapp') },
      notification_type: { required },
      send_to_medium: { required },
      template_id: { required: requiredIf(form => form.notification_type == 'whatsapp') },
      action_type: { required },
      action_id: { required: requiredIf(form => form.action_type != 'custom') },
      notification_title: { required: requiredIf(form => form.notification_type === "app_notification") },
      notification_description: { required: requiredIf(form => form.notification_type === "app_notification") },
      device_type: { required: requiredIf(form => form.notification_type === "app_notification") },
      data_filter_id: { required: requiredIf(form => form.send_to_medium == 'data_filter' && form.notification_type == 'whatsapp') },
      mobile_numbers: { required: requiredIf(form => form.send_to_medium == 'test') },
      speciality_selected: { required: requiredIf(form => form.send_to_medium == 'speciality' && form.notification_type == 'app_notification') }
    },
  },
};
</script>
 